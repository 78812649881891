<script lang="ts">
  import { getContext } from "svelte";
  import { TABS } from "./Tabs.svelte";

  const tab = "panel_"+(Math.round(Math.random()*100000))+"_"+new Date().getMilliseconds();
  const { registerTab, selectTab, selectedTab } = getContext(TABS);

  export let size: "default" | "compact" | "relaxed" = "default";

  export const activateTab = (e) =>{
    if(e && e.preventDefault){
      e.preventDefault();
    }
    if(e && e.stopPropagation){
      e.stopPropagation();
    }
    selectTab(tab);
  }

  registerTab(tab);

  let tabActive = $selectedTab == tab;
  $: tabActive = $selectedTab == tab;
</script>

<button
  class="tab"
  class:tabDefault={!tabActive}
  class:tabActive
  class:compact={size === "compact"}
  class:relaxed={size === "relaxed"}
  on:click={() => selectTab(tab)}
>
  <slot />
</button>

<style type="text/scss" lang="scss" scoped>
  .tab {
    @apply border-transparent hover:border-gray-100;
    @apply whitespace-nowrap text-sm tracking-wide uppercase font-semibold;
    @apply py-4 px-6 md:px-10 lg:px-24;
    @apply border-b-2 border-transparent;
    @apply md:text-md;
    @apply transition-textcolors;
    /* Focus State */
    @apply outline-none;

    &.compact {
      @apply py-4 px-6 md:px-8;
    }

    &.relaxed {
      @apply py-4 px-6 md:px-10 lg:px-14;
    }
  }
  .tabActive {
    @apply border-gray-900 text-gray-900 hover:text-gray-900 hover:border-gray-900;
  }
  .tabDefault:not(.tabActive) {
    @apply border-transparent text-gray-700 text-opacity-50 hover:text-gray-700 hover:text-opacity-100 hover:border-gray-100;
  }
</style>

<script lang="ts">
  import { getContext } from "svelte"
  import { TABS } from "./Tabs.svelte"
  // Components
  import { Grid } from '$lib/components/organisms/Layout';
  
  export let extended = false

  const mobileTabsTitles: string[] = []
  let previouslySelectedTab = ""
  let selectedOptionMobileTab: string = ""
  let slotContent
  let mobileTabs: any[] = []

  const { tabsList, tabs, registerMobileTab, selectTab, selectedTab } = getContext(TABS)

  $: if (slotContent && $tabsList) {
    mobileTabs = $tabsList.map((tab, i) => {
      if (slotContent.children && slotContent.children[i]) {
        mobileTabsTitles.push(slotContent.children[i].textContent)
      }
      return tab
    })
  }

  $: if ($selectedTab) {
    if ($tabsList.indexOf($selectedTab) != previouslySelectedTab) {
      selectedOptionMobileTab = $tabsList.indexOf($selectedTab)
      previouslySelectedTab = selectedOptionMobileTab
    }
  }

  const selectMobileAction = () => {
    selectTab($tabsList[selectedOptionMobileTab])
  }
</script>

<div class="scrollable-area">
  <Grid noGrid noHorizontalPadding="{extended}">
    <nav class="tab-list" aria-label="Tabs" bind:this="{slotContent}">
      <slot />
    </nav>
  </Grid>
</div>

<style type="text/scss" lang="scss" scoped>
  .scrollable-area {
    @apply overflow-x-scroll relative;
    -webkit-overflow-scrolling: touch;
  }

  .scrollable-area::-webkit-scrollbar {
    @apply hidden;
  }

  .tab-list {
    @apply flex;
    height: var(--tabs-height);
  }
</style>

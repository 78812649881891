<script context="module">
  export const TABS = {}
</script>

<script lang="ts">
  import { setContext, onDestroy } from "svelte"
  import { writable } from "svelte/store"

  export let context: "default" | "page-container" = "default"

  //TODO: move store in dedicated file
  const tabs = []
  const panels = []
  const tabsList = writable([])
  const selectedTab = writable(null)
  const selectedPanel = writable(null)

  setContext(TABS, {
    registerTab: tab => {
      tabs.push(tab)
      tabsList.update(() => tabs);
      selectedTab.update(current => current || tab)

      onDestroy(() => {
        const i = tabs.indexOf(tab)
        tabs.splice(i, 1)
        tabsList.update(() => tabs);
        selectedTab.update(current => (current === tab ? tabs[i] || tabs[tabs.length - 1] : current))
      })
    },

    registerPanel: panel => {
      panels.push(panel)
      selectedPanel.update(current => current || panel)

      onDestroy(() => {
        const i = panels.indexOf(panel)
        panels.splice(i, 1)
        selectedPanel.update(current => (current === panel ? panels[i] || panels[panels.length - 1] : current))
      })
    },

    selectTab: tab => {
      const i = tabs.indexOf(tab)
      selectedTab.update(() => tab);
      selectedPanel.update(() => panels[i]);
    },
    tabsList,
    selectedTab,
    selectedPanel,
  })
</script>

<div class="module-tabs" class:page-container="{context === 'page-container'}">
  <slot />
</div>

<style type="text/scss" lang="scss" scoped>
  .module-tabs.page-container {
    @apply -page-margin;
  }
</style>

<script lang="ts">
  import { getContext } from "svelte"
  import { TABS } from "./Tabs.svelte"
  
  const tabId = "panel_"+(Math.round(Math.random()*100000))+"_"+new Date().getMilliseconds();
  const panel = tabId;
  const { registerPanel, selectedPanel } = getContext(TABS)

  registerPanel(panel)
</script>

{#if $selectedPanel === panel}
  <slot />
{/if}
